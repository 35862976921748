<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <TechnicianCreate :createDialog="technicianCreateDialog" @close="technicianCreateDialog = false" @submit="getTechnician" />
    <TechLocationCreate :createDialog="techLocationCreateDialog" @close="techLocationCreateDialog = false" @submit="getTechLocation" />
    <v-card  grid-list-md>
      <ModelTitle title="New Work Order" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="6">
            <v-autocomplete :items="Serial" clearable :hide-details="selectSerialError.length === 0"
              :error-messages="selectSerialError" hide-no-data v-model="selectedSerial" return-object outlined dense
              item-text="imeiNumber" autofocus label="Select Serial" :search-input.sync="serialSearch" :loading="loading">
              <template v-slot:item="{ item }">
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{item.imeiNumber}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.Product.name}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6">
            <v-text-field
              outlined
              :hide-details="nameErrors.length === 0"
              dense
              :error-messages="nameErrors"
              v-model="WorkOrder.name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6">
            <v-select :items="Technicians" clearable hide-details v-model="WorkOrder.TechnicianId" item-text="name" outlined dense
              item-value="id" label="Select Technician">
              <template v-slot:item="{ item }">
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.phoneNumber}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="technicianCreateDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>Create New Technician</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template></v-select>
          </v-col>
          <v-col cols="6" sm="6">
            <v-select :items="TechLocations" clearable hide-details v-model="WorkOrder.TechLocationId" item-text="name" outlined dense
              item-value="id" label="Select TechLocation">
              <template v-slot:item="{ item }">
                <v-divider class="mb-2"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.phoneNumber}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="techLocationCreateDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>Create New TechLocation</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template></v-select>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              outlined
              dense
              hide-details
              auto-grow
              v-model="WorkOrder.note"
              :label="$t('labels.note')"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WorkOrderService from "../service.js";
import SerialService from "@/modules/Inventory/Serial/service.js";
import TechnicianService from "@/modules/WorkOrder/Technician/service.js"
import TechLocationService from "@/modules/WorkOrder/TechLocation/service.js"

const TechnicianCreate = () => import("@/modules/WorkOrder/Technician/components/Create");
const TechLocationCreate = () => import("@/modules/WorkOrder/TechLocation/components/Create");
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");

export default {
  name: "create-WorkOrder",
  props: {
    createDialog: {
      default: false
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      serialSearch: null,
      selectedSerial: null,
      technicianCreateDialog: false,
      Technicians: [],
      techLocationCreateDialog: false,
      TechLocations: [],
      Serial: [],
      WorkOrder: {
        SerialVaultId: null,
        ProductId: null,
        TechnicianId: null,
        TechLocationId: null,
        note: null,
        name: null,
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    WorkOrder: {
      name: {
        required
      },
      SerialVaultId: {
        required,
        minValue: minValue(1),
      },
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
      if(val){
        this.getSerial()
        this.getTechnician()
        this.getTechLocation()
      }
    },
    serialSearch(){
      this.getSerial()
    },
    selectedSerial(val){
      if(val){
        this.WorkOrder.SerialVaultId = val.id
        this.WorkOrder.ProductId = val.ProductId
        this.WorkOrder.name = val.Product.name
      } else {
        this.WorkOrder.SerialVaultId = null
        this.WorkOrder.ProductId = null
        this.WorkOrder.name = null
      }
    }
  },
  components: {
    TechnicianCreate,
    TechLocationCreate
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.WorkOrder.name.$dirty) return errors;
      if (!this.$v.WorkOrder.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    },
    selectSerialError() {
      const errors = [];
      if (!this.$v.WorkOrder.SerialVaultId.$dirty) return errors;
      if (!this.$v.WorkOrder.SerialVaultId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.WorkOrder.SerialVaultId.minValue)
        errors.push("Pleaes select serial");

      return errors;
    },
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    getTechnician() {
      this.loading = true;
      return TechnicianService.getAll().then((response) => {
        this.loading = false;
        this.Technicians = response.data;
        return response;
      });
    },
    getTechLocation() {
      this.loading = true;
      return TechLocationService.getAll().then((response) => {
        this.loading = false;
        this.TechLocations = response.data;
        return response;
      });
    },
    empty() {
      this.WorkOrder = {
        SerialVaultId: null,
        ProductId: null,
        TechnicianId: null,
        TechLocationId: null,
        note: null,
        name: null
      };
      this.selectedSerial = null,
      this.Serial = [],
      this.$v.$reset();
    },
    getSerial() {
      this.loading = true;
      return SerialService.getAll({hideRepair: true, search: this.serialSearch}).then((response) => {
        this.loading = false;
        this.Serial = response.data;
        return response;
      });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        this.WorkOrder.name = this.WorkOrder.name.charAt(0).toUpperCase() + this.WorkOrder.name.slice(1)
        return WorkOrderService.create(this.WorkOrder)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "WorkOrder is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
